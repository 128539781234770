#tinymce {
  background:white !important;
}
body.home {
  background:$gray-200;
}

.woocommerce .products ul, .woocommerce ul.products {
  background:$gray-200;
  padding:30px 30px 0;
}

.main {
  img {
    max-width:100%;
    height:auto;
  }
}



.content-info {
  background:$white !important;
  padding-top:80px;
  h3 {
    font-size:20px;
  }
  address {
    margin-top:20px;
  }
  .fa {
    margin-right:10px;
  }
  .indent {
    margin-left:25px;
  }
}

.contact-info {
  address {
    margin-top:20px;
  }
  .fa {
    margin-right:10px;
  }
  .indent {
    margin-left:25px;
  }
}


.social-media {
  margin-top:20px;
  a {
    color:$gray-500;
    text-decoration: none;
    transition: all 0.3s ease;
    &:hover {
      color:theme-color('primary');
    }
    i.fa {
      font-size:30px;
      margin-right:5px;
    }
    &:last-of-type {
      margin-right:0;
    }
  }
}

.telemail-link {
  color:$body-color;
  text-decoration:none;
}

.banner {
  padding-top:30px;
  padding-bottom:30px;
  width:100%;
  background-size:cover;
  background-color:theme-color('primary');
  border-bottom:10px solid theme-color('primary');
  margin-bottom:30px;
}

.banner-home {
  padding-bottom:30px;
}

.brand {
  img {
    width:30%;
    height:auto;
    margin-top:40px;
    margin-bottom:30px;
    transition:all 1s ease;
    &:hover {
      transform: scale(1.2);
    }
  }
}

.banner-callout {
  width:40%;
  padding-top:30px;
  h2, p {
    color:white;
    text-transform: none;
  }
  h2 {
    font-family: $font-family-serif;
    font-size:45px;
    font-weight:100;
  }
  p {
    font-size:18px;
    font-weight:100;
  }
  a {
    color:white;
  }
}
.nav-primary {
  width:100%;
  border-bottom:1px solid rgba(255,255,255,0.3);
  padding-bottom:20px;
  padding-left:4%;
  padding-right:4%;
}
.nav {
  font-family: $font-family-sans-serif;
  font-weight:bold;
  font-size:20px;
  text-transform: uppercase;
  a {
    margin-right:50px;
    color:white;
    text-decoration: none;
    transition:all 0.3s ease;
    &:hover {
      color:theme-color('secondary');
    }
  }
  li:last-of-type {
    a {
      margin-right:0;
    }
  }
  li.active a {
    border-bottom: 1px solid theme-color('primary');
    padding-bottom: 23px;
  }
}

.widget {
  .menu, .product-categories {
    padding:0;
    margin:20px 0 0;
    list-style: none;
    li {
      margin-bottom:5px;
    }
    a {
      text-decoration:none;
    }
  }
}

.news-social {
  background-color:$white;
  padding-top:45px;
  padding-bottom:45px;
  background-size:cover;
  margin-top:75px;
  h3 {
    color:white;
  }
  p {
    color:white;
    font-size:15px;
  }
}

.copyright {
  margin-top:60px;
  background:#f5f6f6;
  padding-top:20px;
  padding-bottom:20px;
  width:100%;
  font-size:14px;
}

.page-header-home {
  margin-top:15px;
  margin-bottom:20px;
}

.products {
  .product {
    background:white;
    padding:15px !important;
  }
}

.single-product-main-image {
  margin-bottom:20px;
}

.slick-slide {
  padding-left:3px;
  padding-right:3px;
}

.single-product.woocommerce span.onsale {
  width:100px;
}

.woocommerce .add_to_cart_button {
  display:none !important;
}

.woocommerce-result-count {
  display:block;
  float:none !important;
}

.woocommerce nav.woocommerce-pagination ul li {
  font-family:$font-family-sans-serif;
  font-size:16px;
}

.woocommerce nav.woocommerce-pagination ul li a:focus, .woocommerce nav.woocommerce-pagination ul li a:hover, .woocommerce nav.woocommerce-pagination ul li span.current {
  background:theme-color('primary');
  color:white;
}

.woocommerce ul.products li.product .price,  {
  font-family: $font-family-sans-serif;
  font-weight:bold;
  font-size:20px;
  color:$body-color;
  float:left;
}

.woocommerce div.product p.price, .woocommerce div.product span.price {
  font-family: $font-family-sans-serif;
  font-weight:bold;
  font-size:20px;
  color:$body-color;
  float:left;
}

.woocommerce div.product form.cart {
  display:none;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li.active {
  background: theme-color('primary');
  z-index: 2;
  border-bottom-color: theme-color('primary');
  color:white;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li.active::before {
  box-shadow: 2px 2px 0 theme-color('primary');
}

.woocommerce div.product .woocommerce-tabs ul.tabs li.active::after {
  box-shadow: -2px 2px 0 theme-color('primary');
}

.woocommerce div.product .woocommerce-tabs ul.tabs::before {
  //border-bottom: 1px solid theme-color('primary');
}

.woocommerce-product-details__short-description {
  clear:both;
}

.woocommerce ul.products li.product .price ins, .woocommerce div.product p.price ins, .woocommerce div.product span.price ins {
  float:left;
  text-decoration:none;
}

.woocommerce ul.products li.product .price del, .woocommerce div.product p.price del, .woocommerce div.product span.price del {
  float:right;
  font-size:15px;
  line-height:30px;
  margin-left:10px;
}

.woocommerce span.onsale {
  background:theme-color('secondary');
  color:white;
  font-weight:bold;
  font-family:$font-family-sans-serif;
  text-transform:uppercase;
  margin:0;
  top:0;
  right:0;
  border-radius:0;
}

.woocommerce ul.products li.product .onsale {
  margin:0;
}

.woocommerce ul.products li.product .woocommerce-loop-category__title, .woocommerce ul.products li.product .woocommerce-loop-product__title, .woocommerce ul.products li.product h3 {
  font-family:$font-family-serif;
  color:$body-color;
  text-transform:none;
  font-weight:bold;
  height:100px !important;
}



.woocommerce ul.products li.product, .woocommerce-page ul.products li.product {
  transition:all 0.3s ease;
  &:hover {
    transform:scale(1.1);
  }
}

.woocommerce-product-details__short-description {
  display:none;
}

.product-description {
  display:block;
  clear:both;
  margin-top:30px;
  margin-bottom:30px;
  h3 {
    font-family:$font-family-serif;
    margin-top:30px;
    text-transform:none;
    font-weight:bold;
    font-size:18px;
  }
}

.product_meta {
  display:none;
}


.comment-form input[type=email], .comment-form input[type=text], .comment-form input[type=url], .comment-form textarea, .form-control, .search-form .search-field {
  padding: .5rem 1rem .3rem;
  line-height:2;
}

.back-to-top {
  text-decoration:none;
  color:$body-color;
}

.nice-select {
  font-family: $font-family-sans-serif !important;
  line-height:35px;
  color:$gray-700;
  text-transform:uppercase;
  background-color:$gray-200;
}

article {
  border-bottom:1px solid $gray-300;
  time {
    font-size:16px;
    font-family:$font-family-sans-serif;
  }
  header {
    margin-top:30px;
    h3 {
      margin-bottom:0;
    }
    h3 a {
      text-decoration:none;
    }

  }
  .wp-post-image {
    margin-top:30px;
  }
}

.entry-summary {
  margin-top:15px;
}


#responsive-menu-container #responsive-menu li.responsive-menu-item .responsive-menu-item-link {
  font-size:18px !important;
  text-transform:uppercase;
  font-family: $font-family-sans-serif !important;
}


.woocommerce-page .page-title {
  text-align:center;
}

.nice-select.open .list {
  z-index:10;
}

// Extra Small devices (landscape phones, 576px and up)
@include media-breakpoint-down(sm) {
  .sidebar-footer-middle {
    margin-top:20px;
    margin-bottom:30px;
  }

  aside.sidebar {
    margin-top:50px;
  }

  .content-info {
    text-align:center;
  }

  .brand img {
    width:70%;
  }

  .banner-callout {
    width:80%;
    h2 {
      font-size:30px;
    }
    p {
      font-size:16px;
    }
  }
}

@media (max-width: 1023px) {
.woocommerce ul.products li.product, .woocommerce-page ul.products li.product {
  width: 100%;
}
.woocommerce ul.products li.product .woocommerce-loop-category__title, .woocommerce ul.products li.product .woocommerce-loop-product__title, .woocommerce ul.products li.product h3 {
  height: 60px !important;
}
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {  }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199px) {  }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {  }




